@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables';

//Added to allow for hiding font awesome
[hidden] {
  display: none;
}

.no-background {
  background-color: transparent !important;
}

// Creates utility classes named w100, w200, w300 etc. to corresponding font-weights
$font-weight-prefix: w !default;

@for $i from 1 through 9 {
  .#{$font-weight-prefix}#{($i * 100)} {
    font-weight: 100 * $i !important;
  }
}

// this code is ripped from bootstrap 4
// modified to use em and bootstrap 3 grid-breakpoints

// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1em !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
    (
      0: 0,
      1: ($spacer * .25),
      2: ($spacer * .5),
      3: $spacer,
      4: ($spacer * 1.5),
      5: ($spacer * 3),
      6: ($spacer * .75),
      7: ($spacer * 2),
      8: ($spacer * 2.5)
    ),
    $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
    (
      25: 25%,
      50: 50%,
      75: 75%,
      100: 100%,
      auto: auto
    ),
    $sizes
);

$grid-breakpoints: (
  xs: 0,
  sm: $screen-xs,
  md: $screen-sm,
  lg: $screen-md,
  xl: $screen-lg
) !default;

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// stylelint-disable declaration-no-important
// Margin and Padding

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
        }
        .#{$abbrev}r#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
        }
        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}l#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left: $length !important;
        }
      }
    }

    // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
    @each $size, $length in $spacers {
      @if $size != 0 {
        .m#{$infix}-n#{$size} {
          margin: -$length !important;
        }
        .mt#{$infix}-n#{$size},
        .my#{$infix}-n#{$size} {
          margin-top: -$length !important;
        }
        .mr#{$infix}-n#{$size},
        .mx#{$infix}-n#{$size} {
          margin-right: -$length !important;
        }
        .mb#{$infix}-n#{$size},
        .my#{$infix}-n#{$size} {
          margin-bottom: -$length !important;
        }
        .ml#{$infix}-n#{$size},
        .mx#{$infix}-n#{$size} {
          margin-left: -$length !important;
        }
      }
    }

    // Some special margin utils
    .m#{$infix}-auto {
      margin: auto !important;
    }
    .mt#{$infix}-auto,
    .my#{$infix}-auto {
      margin-top: auto !important;
    }
    .mr#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-right: auto !important;
    }
    .mb#{$infix}-auto,
    .my#{$infix}-auto {
      margin-bottom: auto !important;
    }
    .ml#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-left: auto !important;
    }
  }
}

// Utilities for common `display` values
$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex !default;

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $value in $displays {
      .d#{$infix}-#{$value} {
        display: $value !important;
      }
    }
  }
}

// Utilities for toggling `display` in print
@media print {
  @each $value in $displays {
    .d-print-#{$value} {
      display: $value !important;
    }
  }
}

// Width and height utils

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
$sizes: map-merge(
    (
      25: 25%,
      33: 33.33333%,
      50: 50%,
      67: 66.66666%,
      75: 75%,
      100: 100%,
      auto: auto
    ),
    $sizes
);

@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }
  }
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

// Viewport additional helpers

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}
