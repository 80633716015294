$theme-font-path: '../../../../../fonts';

@import '../../node_modules/huk-components/dist/collection/higux/bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../node_modules/huk-components/dist/collection/higux/hig-ui-kit/app/scss/partials/variables';
@import '../../node_modules/huk-components/dist/collection/higux/bootstrap-sass/assets/stylesheets/bootstrap/mixins';
@import '../../node_modules/huk-components/dist/collection/higux/bootstrap-sass/assets/stylesheets/bootstrap/normalize';
@import '../../node_modules/huk-components/dist/collection/higux/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding';
@import '../../node_modules/huk-components/dist/collection/higux/hig-ui-kit/app/scss/partials/mixins';
@import '../../node_modules/huk-components/dist/collection/higux/hig-ui-kit/app/scss/partials/fonts';

@import 'helper-classes.scss';

/* HIG Style - override BS5 reboot styles */
body {
  font-family: "HCo Gotham", arial, sans-serif !important;
  font-size: 14px !important;
  line-height: 1.428571429 !important;
  color: #333333 !important;
}
